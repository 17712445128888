import React, { useState, useEffect } from 'react';
const CookieConsent = ()=>{
    const [visible,setVisibility] = useState(true);
    const hideCookieConsent=()=>{
        setVisibility(false);
    }
    return (<>{visible?<div class="cookie">
    <div class="notification font-sm open-sans">Your Opt out Preference Signal is Honored</div>
    <div class="w-100 padding-md">
    <div class="font-xsm w-70 float-left">We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. Some of these cookies also help improve your user experience on our websites, assist with navigation and your ability to provide feedback, and assist with our promotional and marketing efforts. Please read our Cookie Policy for a more detailed description and click on the settings button to customize how the site uses cookies for you.</div>
    <div class="w-50d float-lefdt center ">
    <button class="active" onClick={hideCookieConsent}>ACCEPT COOKIES</button>
    <button class="inactive">COOKIE SETTINGS</button>
    </div> </div>
            </div>:<></>}</>)
}
export default CookieConsent;