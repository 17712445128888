import React from 'react';
import Helmet from "react-helmet"

export default class AdServe extends React.Component {

    render() {
        return (
                <Helmet>
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="@nytimesbits" />
                    <meta name="twitter:image" content="https://peelytheblog.com/sfd/images/k1ym91726908142350" />
                    <meta name="twitter:site" content="@nytimesbits" />
                    <meta name="twitter:creator" content="@nickbilton" />
                </Helmet>
                );
    }
}
