import { render } from "react-dom";
import React, { useState } from "react";
import axios from 'axios';
import {Helmet} from "react-helmet-async";
import Nav from './Nav';
import swal from 'sweetalert';
import ReactMarkdown from 'react-markdown'
import ReactDom from 'react-dom'
import remarkGfm from 'remark-gfm'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RotatingSquare } from 'react-loader-spinner'
import { Link, BrowserRouter as Router, Switch, Route } from 'react-router-dom';

class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {title: "", contributor: "", about: "", article: {}, loading: false, articles: [], main_tags: [], imageURL2: "https://peelytheblog.com/sfd/images/", imageURL1: "http://localhost:3030/images/", responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: {max: 4000, min: 3000},
                    items: 5
                },
                desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 8
                },
                tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 3
                },
                mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 2
                }
            }};

    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.componentDidMount();

        }
    }
    async componentDidMount() {
        this.setState({loading: true});
        const response = await axios.get("/api/v1/blog/article/" + this.props.match.params.id)
                .then((response) => {
                    document.title = this.state.title;
                    this.setState({article: response.data[0]});
                    this.setState({title: response.data.title});

                    axios.get("/api/v1/blog/category/all/" + response.data[0].main_tag)
                            .then((response) => {
                                this.setState({articles: response.data});
                            }).catch((err) => {
                    });
                }).catch((err) => {
        });
        axios.get("/api/v1/blog/article/maintags/" + this.props.match.params.id)
                .then((response) => {
                    this.setState({main_tags: response.data});
                    this.setState({loading: false});

                }).catch((err) => {
        });







    }

    render() {
        if (this.state.loading === true) {

            return(
                    <div class="centerpage">
                        <Helmet>
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content="@nytimesbits" />
                            <meta name="twitter:image" content="https://peelytheblog.com/sfd/images/k1ym91726908142350" />
                            <meta name="twitter:site" content="@nytimesbits" />
                            <meta name="twitter:creator" content="@nickbilton" />
                        </Helmet>
                        <RotatingSquare
                    
                            height="100"
                            width="100"
                            color="#588507"
                            ariaLabel="rotating-square-loading"
                            strokeWidth="4"
                            wrapperStyle={{}}
                            wrapperClass="margin-0d"
                            visible={true}
                    
                            />
                    </div>)
        } else
            return(
                    <div class="margin-top-md">
                        <Helmet>
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content="@nytimesbits" />
                            <meta name="twitter:image" content="https://peelytheblog.com/sfd/images/k1ym91726908142350" />
                            <meta name="twitter:site" content="@nytimesbits" />
                            <meta name="twitter:creator" content="@nickbilton" />
                        </Helmet>
                        {this.state.loading}
                        <Nav/>
                        <div class="relative session margin-top-md Roboto float-lefdt padding-md">
                            <div class="w-100 text-bold display-flexs   margin-lg-left-0  ">
                                <span class="popular absolute left-10 pedels Ubuntu border-opt-1 font-md padding-sm  onhover-green">POPULAR</span>
                                <div class="w-100">
                                    <div class=" margin-ssm absolute right-10 bg-grey center"><input type="text" class=" padding-md border-1 hidded populdar margin-ssm bg-gray  peelss border-none w-30 Mulish outline-none" placeholder="Enter a search term ..."/><button class=" border-rad-sm  margin-right-sm   padding-md Mulish text-white border-none outline-none margin-sm center font-sm search bg-crimson pointer"><span class="material-symbols-outlined font-md-x ">
                                                search
                                            </span></button><div> <span class="material-symbols-outlined searchs bg-gray padding-sm font-md-x text-white margin-sm pointer border-rad-sm">
                                                dark_mode
                                            </span><span class="material-symbols-outlined searchs bg-gray padding-sm font-md-x text-white margin-sm pointer border-rad-sm">
                                                settings
                                            </span> <span class="material-symbols-outlined  searchs bg-gray padding-sm font-md-x  text-white margin-sm pointer border-rad-sm">
                                                login
                                            </span></div></div></div>
                            </div>
                        </div>
                        <div class="article-container session margin-top-md"> 
                    
                            <div class="  Roboto art text-gray ">
                                <div class="header border-rad-sm " style={{backgroundImage: `url(${this.state.imageURL2 + this.state.article.main})`}}><div class="font-xl  margin-top-m Roboto text-bold text-gray"> <span class="text-salmon"></span></div></div>
                    
                                <h4 class="Mulish font-ldg-l text-white">{this.state.article.title}</h4>
                                <div class=" font-sm">
                                    {this.state.main_tags.map(tag => <Link to={'/articles/' + tag.main_tag}> <span class="badge font-sm text-white margin-sm">{tag.main_tag}</span></Link>)}
                                </div>
                                <div class=" border-bottom-crimson bg-crimson margin-top-xssm"></div>
                                <p class="font-xsm ">{this.state.article.contributor} | {new Date(this.state.article.date).toDateString()}</p>
                                <div class="line-spacer  text-white Mulish">
                                    <ReactMarkdown children={this.state.article.body} remarkPlugins={[remarkGfm]} />       
                                </div>
                            </div>
                            <div class="header-bar  s  paddidng-md">
                                <div class="w-100 text-bold margin-lg-left-0 hidden Roboto">
                                    <span class="popular  peels Ubuntu border-opt-1 padding-md onhover-green pointer">RELATED</span>
                                </div>
                                <div class="content-body-sm side-list">
                                    {this.state.articles.map(a =>
                                        <Link to={'/article/' + a.id} class=" border-rad-sm border-variant-f1 bgg-white content-box color-variant-1 padding-sm">
                                        <div class="grid-container-2 flex-vertical ">
                                            <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                            <div class="font-sm center about align-left">
                                                <span> {a.title}</span>
                        
                                            </div></div>
                                        </Link>)}
                    
                                </div>
                            </div>
                        </div>
                        <div class="  padding-md hidden show">
                            <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                <span class="popular  peels Ubuntu border-opt-1 padding-md onhover-green pointer">RELATED</span>
                            </div>
                            <div class="content-body-md">
                                {this.state.articles.map(a =>
                                        <Link to={'/article/' + a.id} class=" border-rad-sm border-variant-d1 bg-whited content-box dcolor-variant-1 padding-sm">
                                        <div class="grid-container-2   ">
                                            <div class="profile" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                            <div class="font-sm center about align-left">
                                                <span> {a.title}</span>
                                            </div></div>
                                        </Link>)}
                    
                            </div>
                        </div>
                    
                        <div class="content-body-mdd session">
                            <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                <span class="popular  peels Ubuntu border-opt-1 padding-md onhover-green pointer">INTERESTING</span>
                            </div>
                            <div class="content-body-sm w-100">
                                <Carousel responsive={this.state.responsive} showDots={false} autoPlay={true}>
                    
                                    {this.state.articles.map(a =>
                                        <div class=" margin-md-1 border-rad-sm bg-grayf border-1 content-box height-1">
                                            <Link to={'/article/' + a.id} class="header-bar-side-ditem-4   s pointer text-white  play font-sm">
                                            <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}>
                                                <span class="background-green line-spacer font-md font-bold Mulish  ">{a.title}</span>
                                            </div>
                                            <span classs="background-green line-spacer font-md font-bold Mulish  ">{a.title}</span>
                                            </Link></div>)}
                                </Carousel>
                            </div>
                        </div>
                        <div class="center-1 margin-sm"><div class="background-green center-1 border-rad-sm Mulish padding-md text-white pointer">Load more <span class="material-symbols-outlined">
                                    keyboard_arrow_down
                                </span></div></div>
                    </div>
                    );
    }
}
export default Article;



