import Nav from './Nav';
import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { RotatingSquare } from 'react-loader-spinner'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
class ps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {title: "", contributor: "", about: "", article: {}, loading: false,all:[], articles: [],imageURL2: "https://peelytheblog.com/sfd/images/", imageURL1: "http://localhost:3030/images/", responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: {max: 4000, min: 3000},
                    items: 5
                },
                desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 1
                },
                tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 1
                },
                mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 1
                }
            }};

    }
componentDidUpdate(prevProps) {
  if (prevProps.match.params.id !== this.props.match.params.id) {
    this.componentDidMount();
  }
}
    async componentDidMount() {
        this.setState({loading: true});
        await axios.get("/api/v1/blog/articles/category/" + this.props.match.params.id)
                .then((response) => {
                    this.setState({articles: response.data});
                    this.setState({title: response.data.title})
                    
                }).catch((err) => {
        });

        
        const response = await axios.get("/api/v1/blog/category/all/" + this.props.match.params.id)
                .then((response) => {
                    this.setState({all: response.data});
                    this.setState({loading: false});

                }).catch((err) => {
        });


    }

    render() {
        return(
                <>
        
        
        {this.state.loading ? <div class="centerpage">
        
            <div class="margin-0d">
                <RotatingSquare
        
                    height="100"
                    width="100"
                    color="#588507"
                    ariaLabel="rotating-square-loading"
                    strokeWidth="4"
                    wrapperStyle={{}}
                    wrapperClass="margin-0d"
                    visible={true}
                    /></div></div> :
                <div class="margin-top-md">
                    <Nav/>
                    <div class="relative session margin-top-md Roboto float-lefdt padding-md">
                                        <div class="w-100 text-bold display-flexs   margin-lg-left-0  ">
                                            <span class="popular absolute left-10 pedels Ubuntu border-opt-1 font-md padding-sm  onhover-green">POPULAR</span>
                                            <div class="w-100">
                                            <div class=" margin-ssm absolute right-10 bg-grey center"><input type="text" class=" padding-md border-1 hidded populdar margin-ssm bg-gray  peelss border-none w-30 Mulish outline-none" placeholder="Enter a search term ..."/><button class=" border-rad-sm  margin-right-sm   padding-md Mulish text-white border-none outline-none margin-sm center font-sm search bg-crimson pointer"><span class="material-symbols-outlined font-md-x ">
                                                        search
                                                        </span></button><div> <span class="material-symbols-outlined searchs bg-gray padding-sm font-md-x text-white margin-sm pointer border-rad-sm">
dark_mode
</span><span class="material-symbols-outlined searchs bg-gray padding-sm font-md-x text-white margin-sm pointer border-rad-sm">
settings
</span> <span class="material-symbols-outlined  searchs bg-gray padding-sm font-md-x  text-white margin-sm pointer border-rad-sm">
login
</span></div></div></div>
                                        </div>
                                        </div>
                    <div class="session">
                        <div class="">
                            <div class="header-bar-main padding-md ">
                
                                <Carousel responsive={this.state.responsive} showDots={true} autoPlay={true}>
                
                
                                    {this.state.articles.map(a =>
                                        <Link to={'/article/' + a.id} class=" border-rad-sm border-variant-1d  color-variant-d1 padding-sm">
                    
                                        <div class="header" style={{backgroundImage: `url(${this.state.imageURL2 + a.main})`}}><span class="background-green text-white padding-mfd text-bold text-white font-lg w-30">{a.title}</span></div>
                                        <div class="font-sm center about align-left">
                                            <div>
                                                <span class="text-white"> {a.title}</span>
                    
                                            </div></div>
                                        </Link>)}
                
                                </Carousel>
                
                            </div>
                            <div class="margin-md header-bar-aside center border-31 flex-center"><img src="https://naotw-pd.s3.amazonaws.com/styles/aotw_detail_ir/s3/images/GSER_J9803_E2327_A_3_EG4.jpg?itok=-5dYcAT5" height="280px" class="center margin-md"/></div>
                                        
                        </div> 
                        <div class="content-body-sm w-100">
                            {this.state.all.map(a =>
                                <Link to={'/article/' + a.id} class=" border-rad-sm border-variant-1d  bg-whited content-box color-variant-1 padding-sm">
                                <div class="grid-container-2d  ">
                                    <div class="profile-2" style={{backgroundImage: `url(${ this.state.imageURL2+ a.thumbnail})`}}></div>
                                    <div class="font-sm centerd about align-left">
                                        <span> {a.title}</span>
                                    </div></div>
                                </Link>)}
                
                        </div>
                    </div>
                </div>}</>);
    }
}
;
export default ps;

