import { Link, NavLink } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {notifications: [], error: "", mainTags: []};
        this.showMenuItems = this.showMenuItems.bind(this);

    }
    async componentDidMount() {

        const response = await axios.get("/api/v1/tags/maintags/")
                .then((response) => {

                    this.setState({mainTags: response.data});
            
                }).catch((err) => {
        });

    }
    showMenuItems() {
        if (document.getElementById("menu").style.display === "block")
        {
            document.getElementById("menu").style.display = "none";
        } else
            document.getElementById("menu").style.display = "block";
    }
    render() {

        return (
                <div className="top-nav font-sdm text-bold text-gray flex-verticdal fledx-center">
                    <Link to="/">
                    <img src="/images/logo.png" width="130px" class="paddingsm margin-sm float-left"/>
                    </Link>
                    <div class="padding-md p menu-items">
                        {this.state.mainTags.map(tag =>
                                        <NavLink to={'/articles/' + tag.id} activeClassName="selected-2" className='nav-item margin-sm' >
                                        <div class="Mulishd Ubuntu font-sm ">
                                                <div className='' title="Data&Reports">
                                
                                
                                                </div>
                                                {tag.tag}
                                            </div>
                                        </NavLink>
                                    )}
                
                
                
                    </div>
                    <div class="mini-menu center-items float-right Roboto text-white margin-md pointer relative" onClick={this.showMenuItems}> Peels
                <span class="material-symbols-outlined  center nav-item text-white float-right ">
widgets
</span>
      <div class=" p absolute background-green" id="menu">
                        {this.state.mainTags.map(tag =>
                                        <NavLink to={'/articles/' + tag.id} activeClassName="selected-2" className='nav-item items margin-sm' >
                                        <div class="Mulish font-l">
                                                <div className='' title="Data&Reports">
                                
                                
                                                </div>
                                                {tag.tag}
                                            </div>
                                        </NavLink>
                                    )}
                            </div>
        </div></div>
                );
    }
}
export default Nav;