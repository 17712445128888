import Nav from './Nav';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import ImageUpload from './ImageUpload';
import axios from 'axios';
import Adserve from './AdServe';
import { RotatingSquare } from 'react-loader-spinner'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Cookie from 'js-cookie';
import CookieConsent from './CookieConsent';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {articles: [], loading: false, trends: [], international: [], sports: [], local: [], entertainment: [], nature: [], technology: [], trending: [], cars: [], item: [], imageURL2: "https://peelytheblog.com/sfd/images/", imageURL: "http://localhost:3030/images/", responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: {max: 4000, min: 3000},
                    items: 5
                },
                desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 1
                },
                tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 1
                },
                mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 1
                }
            }, responsive2: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: {max: 4000, min: 3000},
                    items: 5
                },
                desktop: {
                    breakpoint: {max: 3000, min: 1024},
                    items: 7
                },
                tablet: {
                    breakpoint: {max: 1024, min: 464},
                    items: 3
                },
                mobile: {
                    breakpoint: {max: 464, min: 0},
                    items: 2
                }
            }};

    }
    async componentDidMount() {
        Cookie.set("interests", "money,trump");
        this.setState({loading: true});
        await axios.get("api/v1/blog/all/")
                .then((response) => {
                    this.setState({articles: response.data});

                }).catch((err) => {
        });

        await axios.get("/api/v1/blog/categories/all/")
                .then((response) => {
                    this.setState({trends: response.data});

                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/international")
                .then((response) => {
                    this.setState({international: response.data});


                }).catch((err) => {
        });

        await axios.get("/api/v1/blog/category/technology")
                .then((response) => {
                    this.setState({technology: response.data});


                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/trend")
                .then((response) => {
                    this.setState({trending: response.data});


                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/nature")
                .then((response) => {
                    this.setState({nature: response.data});


                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/entertainment")
                .then((response) => {
                    this.setState({entertainment: response.data});


                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/sports")
                .then((response) => {
                    this.setState({sports: response.data});


                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/local")
                .then((response) => {
                    this.setState({local: response.data});


                }).catch((err) => {
        });
        await axios.get("/api/v1/blog/category/cars")
                .then((response) => {
                    this.setState({cars: response.data});
                    this.setState({loading: false});

                }).catch((err) => {
        });
    }
    render() {
        return(<>
        
        
        {this.state.loading ? <div class="centerpage">
        
            <div class="margin-0d">
                <RotatingSquare
        
                    height="100"
                    width="100"
                    color="#588507"
                    ariaLabel="rotating-square-loading"
                    strokeWidth="4"
                    wrapperStyle={{}}
                    wrapperClass="margin-0d"
                    visible={true}
                    /></div></div> :
                            <div class="">
                                <Nav/>
                                <div class="session">
                                    <div class="relative margin-top-md Roboto float-lefdt padding-md">
                                        <div class="w-100 text-bold display-flexs   margin-lg-left-0  ">
                                            <span class="popular absolute left-10 pedels Ubuntu border-opt-1 font-md padding-sm  onhover-green">POPULAR</span>
                                            <div class="w-100">
                                                <div class=" margin-ssm absolute right-10 bg-grey center"><input type="text" class=" padding-md border-1 hidded populdar margin-ssm bg-gray  peelss border-none w-30 Mulish outline-none" placeholder="Enter a search term ..."/><button class=" border-rad-sm  margin-right-sm   padding-md Mulish text-white border-none outline-none margin-sm center font-sm search bg-crimson pointer"><span class="material-symbols-outlined font-md-x ">
                                                            search
                                                        </span></button><div> <span class="material-symbols-outlined searchs bg-gray padding-sm font-md-x text-white margin-sm pointer border-rad-sm">
                                                            dark_mode
                                                        </span><span class="material-symbols-outlined searchs bg-gray padding-sm font-md-x text-white margin-sm pointer border-rad-sm">
                                                            settings
                                                        </span> <span class="material-symbols-outlined  searchs bg-gray padding-sm font-md-x  text-white margin-sm pointer border-rad-sm">
                                                            login
                                                        </span></div></div></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div clasks="display-flex">
                                            <div clasks="header-bar-mains border-rad-sm w-100">
                                                <Carousel responsive={this.state.responsive} showDots={true} autoPlay={true}>
                                                    {this.state.trends.map(a =>
                                                        <Link to={'/article/' + a.id} class=" border-rad-sddm borde2r-variant-1 padding-md  coslor-variant-1 padsdinfg-sm">
                                
                                                        <div class="header relative border-rad-sm" style={{backgroundImage: `url(${this.state.imageURL2 + a.main})`}}><span class="background-green text-white padding-mfd text-bold text-white  w-30"><div class="absolute  relative-position-1 text-bold font-xl  Mulish dark-shadow ">{a.title}</div></span><span class="badge bg-crimson absolute bottom-right border-rad-sm center font-sm  text-white Mulish"><span class="material-symbols-outlined searchs   font-md-x text-white  pointer border-rad-sm">
                                                                    visibility
                                                                </span>{a.hits}</span></div>
                                                        <div class="font-sm center about align-left">
                                                            <div>
                                                                <span class="hidden"> {a.title}</span>
                                                            </div>
                                                        </div>
                                                        </Link>)}
                            
                                                </Carousel>
                            <Adserve/>
                                            </div>
                                            <div classs="margin-mds header-bar-aside center border-31 hidden flex-center"><img src="hdttps://naotw-pd.s3.amazonaws.com/styles/aotw_detail_ir/s3/images/GSER_J9803_E2327_A_3_EG4.jpg?itok=-5dYcAT5 " height="3x00px" class="center hidden margdin-md"/></div>
                                        </div>
                                    </div>
                                    <div class="bg-1 border-rad-sm padding-sm">
                                        <div class=" padding-ssm">
                                            <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                                <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">TECHNOLOGY</span>
                                            </div>
                                        </div>
                            
                                        <div class="content-body-sm">
                                            {this.state.technology.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1d content-box color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centers about align-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute font-sm Mulish text-white">  Technology </span>
                                                            </div> 
                                                        </div>
                                                    </div>
                                
                                                </div>
                                                </Link>)}
                            
                                        </div></div>
                                    <div class="bg-1 border-rad-sm padding-sm margin-top-sm padding">
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">SPORTS</span>
                                        </div>
                            
                                        <div class="content-body-sm">
                                            {this.state.sports.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1x content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centers about align-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute font-sm Mulish text-white">  Sports </span> </div></div>
                                                    </div>
                                                </div>
                                                </Link>)}
                            
                                        </div>
                                    </div>
                            
                                    <div class=" padding-">
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">ENTERTAINMENT</span>
                                        </div>
                            
                                        <div class="content-body-sm">
                                            {this.state.entertainment.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1v content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centesr about align-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute font-sm Mulish text-white">  Entertainment </span>
                                                            </div> 
                                                        </div>
                                                    </div></div>
                                                </Link>)}
                            
                                        </div>
                                    </div>
                            
                                    <div class=" padding-mds">
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">CARS</span>
                                        </div>
                            
                                        <div class="content-body-sm">
                                            {this.state.cars.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1v content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centedr about align-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute font-sm Mulish text-white">  Cars </span>
                                                            </div> 
                                                        </div>
                                                    </div></div>
                                                </Link>)}
                            
                                        </div>
                                    </div>
                                    <div class="bg-1 margin-top-md">
                                        <Carousel responsive={this.state.responsive2} showDots={false} autoPlay={true}>
                            
                                            {this.state.trends.map(a =>
                                                <Link to={'/article/' + a.id} class="header-bar-side-ditem-4   s pointer text-white  play font-sm">
                                                <div class=" relative margin-md-1 border-rad-sm bg-grayf border-0 content-box height-1">
                                
                                                    <div class="profile-3" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}>
                                                        <span class="background-green border-rad-sm line-spacer font-md text-white text-bold margin-md font-bold Mulish  ">{a.title}</span>
                                                    </div>
                                
                                
                                                    <div class="margin-sm absolute bottom-0">
                                                        <button class="bg-dark-green  Mulish pointer center-text text-gray font-bold text-bold  w-100  border-rad-sm border-none ">OPEN</button> 
                                                    </div>
                                                </div></Link>)}
                                        </Carousel>
                                    </div>
                                    <div class=" padding-mds">
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">NATURE</span>
                                        </div>
                                        style
                                        <div class="content-body-sm">
                                            {this.state.nature.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1v content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centerd about align-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute font-sm Mulish text-white"> Nature </span>
                                                            </div> 
                                                        </div>
                                                    </div></div>
                                                </Link>)}
                            
                                        </div>
                                    </div>
                            
                                    <div class=" padding-mds">
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">TRENDING</span>
                                        </div>
                            
                            
                                        <Adserve/>
                            
                                        <div class="content-body-sm">
                                            {this.state.trending.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1v content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centedr about align-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute  font-sm Mulish text-white">  Trends </span>
                                                            </div> 
                                                        </div>
                                                    </div></div>
                                                </Link>)}
                            
                                        </div>
                                    </div>
                            
                                    <div class=" padding-mdd">
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">LOCAL</span>
                                        </div>
                            
                                        <div class="content-body-sm">
                                            {this.state.local.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1v content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centerd about aldign-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge top-left rotate-x absolute  font-sm Mulish text-white">  Local news </span>
                                                            </div> 
                                                        </div>
                                                    </div></div>
                                                </Link>)}
                            
                                        </div>
                                    </div>
                            
                                    <div class=" padding-mds">
                            
                                        <div class="w-100 text-bold margin-lg-left-0  Roboto">
                                            <span class="popular  peels Ubuntu border-opt-1 padding-sm onhover-green pointer">INTERNATIONAL</span>
                                        </div>
                            
                                        <div class="content-body-sm">
                                            {this.state.international.map(a =>
                                                <Link to={'/article/' + a.id} class="relative border-rad-sm border-variant-1v content-box bg-whites color-variant-1 padding-sm">
                                                <div class="grid-container-2d  ">
                                                    <div class="profile-2" style={{backgroundImage: `url(${this.state.imageURL2 + a.thumbnail})`}}></div>
                                                    <div class="font-sm centesr about alignd-left">
                                                        <div class="">
                                                            <span> {a.title}</span>
                                                            <div>
                                                                <span class="badge  bottom-rigdht top-left rotate-x absolute  font-sm Mulish text-white">  International  </span>
                                                            </div> 
                                                        </div>
                                                    </div></div>
                                                </Link>)}
                            
                                        </div>
                                    </div></div>
                            
                            </div>}</>);

    }

}
;
export default Home;
