import './App.css';
import Home from './Home'
import Article from './Article';
import Upload from './ArticleCreator';
import ArticleQuery from './ArticleQuery';
import axios from 'axios';
import CookieConsent from './CookieConsent';
import AdServe from './AdServe';
import {BrowserRouter as Router, Route, Routes, Switch, withRouter}
from 'react-router-dom';

function App() {
    
   axios.defaults.baseURL='https://peelytheblog.com/sfd';
   // axios.defaults.baseURL='http://localhost:3030/sfd';
    return (
            <div className="App">
                <Router>
                    <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/ad" component={AdServe}/>
                    <Route exact path ="/publish" component={Upload}/>
                    <Route exact path ="/articles/:id/" component={ArticleQuery}/>
                    <Route exact path ="/article/:id/" component={Article}/>
                    </Switch>
                </Router>
                <CookieConsent/>
            </div>
            );
}

export default App;
